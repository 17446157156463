import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Loader from "../components/loader"
import { connect } from "react-redux"
import aboutUsBanner from "../assets/images/aboutUs.png"
import api from "../_api/dataApi"
import { error } from "../utils"
import { FormattedMessage } from "react-intl"

const AboutUs = ({ lang }) => {
  const [content, setContent] = useState("")
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    api
      .getConstantPages("about-us")
      .then(res => {
        setContent(res?.content)
      })
      .catch(() => error())
      .finally(() => setLoading(false))
  }, [lang])

  return (
    <Layout>
      <SEO title="About Us" />
      {loading && <Loader />}
      <div className="pt-5" style={{ paddingTop: "40px" }}>
        <div
          className="px38 aboutSimsarMainText"
        >
          <div className="signInBorder d-flex">
            <span
              className="font-black px38 mx-3 aboutSimsarText"
            >
              <FormattedMessage defaultMessage="About Simsar" />
            </span>
          </div>
        </div>
        <div className="py-lg-4 py-2 br-8">
          <img
            alt="aboutUs"
            src={aboutUsBanner}
            className="aboutUs"
          />
        </div>
        <div
          className="container py-lg-3 py-2 px-6rem aboutUsWeAreBestBrokersText"
        >
          <p className="font-semi-bold black px36">
            <FormattedMessage defaultMessage="We are your best brokers" />
          </p>
          {content && (
            <div
              className="darkGrey px20 my-lg-5 my-3 text-start"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = ({ settings: { lang } }) => ({ lang })
export default connect(mapStateToProps)(AboutUs)
